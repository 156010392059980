import { defineStore } from 'pinia'
import buildQueryString from '../../helpers/query-builder'

export const useShipmentsStore = defineStore('shipments', {
  state: () => ({
    baseUrl: '',
    exportCsvUrl: '',
    filters: {
      // Initial filters
      brand: '',
      box_month_content: '',
      content_description: '',
      csv: '',
      printed_at: {
        starts_at: undefined,
        ends_at: undefined
      },
      shipment_invoice_name: '',
      shipment_name: '',
      shippable_starts_at: {
        starts_at: undefined,
        ends_at: undefined
      },
      shippable_ends_at: {
        starts_at: undefined,
        ends_at: undefined
      },
      shipper_memo: '',
      shipping_methods: {
        include: []
      },
      shipping_method_print_option: '',
      subscription_box_brands: {
        include: []
      },
      transacted: undefined,
      country: ''
    },
    shippingMethods: [],
    shippingMethodPrintOptions: [],
    boxBrands: [],
    countries: [],
    transactedOptions: [
      { id: 'all', name: 'All' },
      { id: '1', name: 'Yes' },
      { id: '0', name: 'No' }
    ],
    printStatusOptions: [
      { id: 'all', name: 'All' },
      { id: 'processing', name: 'Processing' },
      { id: 'printed', name: 'Printed' },
      { id: 'transacted', name: 'Transacted' },
      { id: 'cancelled', name: 'Cancelled' },
      { id: 'print_failed', name: 'Print Failed' },
    ],
    exportType:[
      {id: "shipment_csv", name: "Shipment CSV"},
      {id: 'ioss', name: 'Ioss'},
    ]
  }),

  getters: {
    isBrandSubscriptionBoxes: (state) => state.filters.brand === 'SubscriptionBoxes',
    isTestShippingSelected: (state) =>
      state.filters.shipping_methods.include.includes(
        Object.keys(state.shippingMethods).find(
          (key) => state.shippingMethods[key] === 'Test Shipping'
        )
      )
  },

  actions: {
    setInitialState(data) {
      const initialData = data.value
      Object.keys(initialData).forEach((key) => {
        // We want to make sure the filters are set individually and not replaced as a whole object.
        if (key === 'filters') {
          this.setFilters(initialData[key])
          return
        }
        this[key] = initialData[key]
      })
    },
    setFilters(filters) {
      Object.keys(filters).forEach((key) => {
        this.filters[key] = filters[key]
      })
    },
    handleSubmit(event) {
      const bodyParams = buildQueryString(this.filters)

      const url = `${event === 'csv' ? this.exportCsvUrl : this.baseUrl}?${bodyParams}`

      window.location.href = url
    }
  }
})
